import {useEffect} from "react"
import _ from "lodash"
import {navigate} from "gatsby"

export default () => {
  useEffect(() => {
    window.sessionStorage.setItem("C1Value", "")
    window.sessionStorage.setItem("C2Value", "")
    window.sessionStorage.setItem("keywordValue", "")
    window.sessionStorage.setItem("productPageValue", "")
    navigate("/product/")
  }, [])

  return true
}